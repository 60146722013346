import React, { useEffect, useState } from 'react';
import { Selection, VictoryAxis, VictoryBar, VictoryChart, VictoryLabel, VictoryLine, VictoryTooltip } from 'victory';
import { format } from 'date-fns';
import { useSearchParams } from 'react-router-dom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import Logo from 'assets/images/branding/logo.svg';
import {
	Button,
	ButtonStack,
	ChartLegend,
	DataTable,
	Drawer,
	ExportButton,
	FilterFields,
	LogoOverlay,
	PageHeading,
	Panel,
	Select,
} from 'components';
import {
	convertDOWNumericToString,
	convertMonthNumericToString,
	determineBarWidth,
	determineDomainPadding,
	dynamicFontSizeStyles,
	getColor,
	getFacility,
} from 'utils';
import {
	FacilityUtilizationDetailsResponse,
	FacilityUtilizationOverviewResponse,
	useAppSelector,
	useGetFacilityUtilizationDetailsQuery,
	useGetFacilityUtilizationOverviewQuery,
	useSystem,
} from 'store';
import { useFilters, useToast } from 'context';

export interface Option {
	label: string;
	value: string;
}

interface FacilityUtilizationChartDatum {
	x: string;
	y: number;
	available_minutes: number;
	primetime_minutes: number;
	turnover_minutes: number;
}

class CustomFlyout extends React.Component<{
	x2?: number;
	y2?: number;
	datum?: { available_minutes: number; primetime_minutes: number; turnover_minutes: number; y: number; x: string };
}> {
	render() {
		const { x2, y2, datum } = this.props;
		return (
			<foreignObject x={x2} y={y2} width='100%' height='100%' className='overflow-visible'>
				<div className='bg-white flex flex-col drop-shadow-md w-20'>
					<div className='bg-white p-1 px-3 pl-1'>
						<p className='text-left text-[0.27em] font-semibold'>{datum?.x}</p>
					</div>
					<div className='bg-blue-900 p-1 flex justify-between'>
						<p className='whitespace-nowrap text-left text-[0.25em] font-semibold text-white'>Primetime Utilization</p>
						<p className='text-right text-[0.25em] text-white'>{datum?.y}%</p>
					</div>
					<div className='bg-blue-900 p-1 flex justify-between'>
						<p className='whitespace-nowrap text-left text-[0.25em] font-semibold text-white'>Primetime Minutes</p>
						<p className='text-right text-[0.25em] text-white'>{datum?.primetime_minutes}</p>
					</div>
					<div className='bg-blue-900 p-1 flex justify-between'>
						<p className='whitespace-nowrap text-left text-[0.25em] font-semibold text-white'>Turnover Minutes</p>
						<p className='text-right text-[0.25em] text-white'>{datum?.turnover_minutes}</p>
					</div>
					<div className='bg-blue-900 p-1 flex justify-between'>
						<p className='whitespace-nowrap text-left text-[0.25em] font-semibold text-white'>Available Minutes</p>
						<p className='text-right text-[0.25em] text-white'>{datum?.available_minutes}</p>
					</div>
				</div>
			</foreignObject>
		);
	}
}

class CustomLabel extends React.Component<{
	x?: number;
	y?: number;
	x2?: number;
	y2?: number;
	datum?: { available_minutes: number; primetime_minutes: string; turnover_minutes: number; y: number; x: string };
}> {
	static defaultEvents = [
		{
			target: 'data',
			eventHandlers: {
				onMouseOver: (evt: React.SyntheticEvent<Element, Event>) => {
					const { x, y } = Selection.getSVGEventCoordinates(evt);
					return {
						target: 'labels',
						mutation: () => ({
							// The label will not change position, but the tooltip will change position
							x2: x,
							y2: y,
							active: true,
							fontSize: 0,
						}),
					};
				},
				onMouseOut: () => {
					return { target: 'labels', mutation: () => ({ active: false }) };
				},
			},
		},
	];

	render() {
		// This is where we pass the new x,y for the tooltip
		const { x2, y2 } = this.props;
		const dynamicStyles = dynamicFontSizeStyles();
		const axisLabelStyle = dynamicStyles.axisLabelStyle;
		return (
			<g>
				<VictoryLabel {...this.props} verticalAnchor='middle' style={axisLabelStyle} />
				<VictoryTooltip
					{...this.props}
					pointerLength={0}
					flyoutComponent={<CustomFlyout x2={x2} y2={y2} />}
					style={{ fontSize: 0 }}
				/>
			</g>
		);
	}
}

export function FacilityUtilization() {
	// table display
	const [tableData, setTableData] = useState<FacilityUtilizationDetailsResponse['data']>([]); // arrays are dynamic depending on how much data we have in our db

	// Filters
	const {
		dateRange,
		surgeons,
		daysOfWeek,
		serviceLines,
		encounterTypes,
		rooms,
		procedures,
		utilizationType,
		turnoverTimeThreshold,
		dropDowns,
		saveDropdown,
		resetFilters,
		applyFilters,
		clearFilters,
		filtersAreDirty,
		metadata,
		currentPageLoaded,
		filtersAreFetching,
	} = useFilters();

	// display state
	const { selectedFacility, selectedSystem } = useAppSelector((state) => state.userState);
	const { data: systemData } = useSystem();
	const facilities = systemData?.facilities ?? [];
	const facility = systemData?.facilities?.find((f) => f.id === selectedFacility);
	const system = systemData?.healthsystems.find((h) => h.id === selectedSystem);
	//get target based on facility settings
	const target = facility?.facility_utilization_target ?? 0;

	// Used to hide certain elements printing purposes
	const [searchParams] = useSearchParams();
	const printable = searchParams.get('printable') === 'true' ?? false;
	const view_by_param =
		searchParams.get('view_by') ??
		(dropDowns.viewBy.value !== 'undefined' ? dropDowns.viewBy.value : undefined) ??
		'day_of_week';
	const view_by_label_pararm =
		searchParams.get('view_by_label') ??
		(dropDowns.viewBy.value !== 'undefined' ? dropDowns.viewBy.label : undefined) ??
		'Day of Week';

	const viewBy = view_by_param;
	const viewByLabel = view_by_label_pararm;
	const { createToast } = useToast();
	const [chartData, setChartData] = useState<FacilityUtilizationChartDatum[]>([]);
	const [numBars, setNumBars] = useState<number>(0);

	// There is sometimes a delay in our filters when a user switches pages
	// (which is why we check if currentPageLoaded is equal to our current page),
	// To account for the delay, we tell our RTK Query to skip until we set skipRequest to false.
	const [skipRequest, setSkipRequest] = useState(true);
	useEffect(() => {
		setTimeout(() => {
			if (currentPageLoaded === '/facility-utilization') {
				setSkipRequest(false);
			}
		}, 0);
	}, [currentPageLoaded]);

	const {
		data: facilityUtilizationDetails,
		isFetching: utilizationDetailsFetching,
		error,
	} = useGetFacilityUtilizationDetailsQuery(
		{
			facility_id: selectedFacility,
			turnover_threshold: turnoverTimeThreshold?.applied,
			groupby: viewBy,
			filters: {
				surgeons: surgeons?.applied,
				days_of_week: daysOfWeek?.applied,
				service_lines: serviceLines?.applied,
				encounter_types: encounterTypes?.applied,
				rooms: rooms?.applied,
				procedures: procedures?.applied.map((procedure) => procedure.name),
				start_date: format(dateRange?.applied.startDate, 'M/d/yyyy'),
				end_date: format(dateRange?.applied.endDate, 'M/d/yyyy'),
				utilization_type: utilizationType?.applied,
			},
			useCached: printable,
		},
		{
			skip: skipRequest || filtersAreFetching,
		}
	);

	const { data: facilityUtilizationOverview, isFetching: utilizationOverviewFetching } =
		useGetFacilityUtilizationOverviewQuery(
			{
				facility_id: selectedFacility,
				turnover_threshold: turnoverTimeThreshold?.applied,
				filters: {
					surgeons: surgeons?.applied,
					days_of_week: daysOfWeek?.applied,
					service_lines: serviceLines?.applied,
					encounter_types: encounterTypes?.applied,
					rooms: rooms?.applied,
					procedures: procedures?.applied.map((procedure) => procedure.name),
					start_date: format(dateRange?.applied.startDate, 'M/d/yyyy'),
					end_date: format(dateRange?.applied.endDate, 'M/d/yyyy'),
					utilization_type: utilizationType?.applied,
				},
				groupby: viewBy,
				useCached: printable,
			},
			{
				skip: skipRequest || filtersAreFetching,
			}
		);

	useEffect(() => {
		const data = transformOverviewData(facilityUtilizationOverview);
		setChartData(data.dataArr);
		setNumBars(data.numBars);
		if (data.numBars > 12) {
			createToast({
				title: 'The group by has been changed to show the maximum amount of data possible for the date range selected.',
				variant: 'info',
			});
			dropDowns.update({ ...dropDowns, viewBy: { label: 'Year', value: 'year' } });
			saveDropdown({ ...dropDowns, viewBy: { label: 'Year', value: 'year' } });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [facilityUtilizationOverview]);

	// When data from the endpoint changes or an error is encountered, update the
	// table data appropriately.
	useEffect(() => {
		if (error) {
			setTableData([]);

			if ('data' in error) {
				createToast({
					title: `${error.data}`,
					variant: 'error',
				});
			} else {
				createToast({
					title: 'There was an error connecting to the server.',
					variant: 'error',
				});
			}

			return;
		}
		setTableData(facilityUtilizationDetails?.data ?? []);
	}, [facilityUtilizationDetails, error, createToast]);

	// the charting library has a jagged render when bringing in new data, so we keep
	// the states below to show our loading indicator a few ms longer than its actually loading to cover that up
	const [utilizationDetailsDelayedFetching, setUtilizationDetailsDelayedFetching] = useState(utilizationDetailsFetching);
	const [utilizationOverviewDelayedFetching, setUtilizationOverviewDelayedFetching] =
		useState(utilizationOverviewFetching);

	// when isFetching is switching to true, immediately show the loading indicator
	// when isFetching is switching to false, wait 250ms after done loading to hide the indicator
	useEffect(() => {
		if (utilizationDetailsFetching) {
			setUtilizationDetailsDelayedFetching(utilizationDetailsFetching);
		} else {
			setTimeout(() => {
				setUtilizationDetailsDelayedFetching(utilizationDetailsFetching);
			}, 250);
		}
	}, [utilizationDetailsFetching, utilizationDetailsDelayedFetching, setUtilizationDetailsDelayedFetching]);

	// when isFetching is switching to true, immediately show the loading indicator
	// when isFetching is switching to false, wait 250ms after done loading to hide the indicator
	useEffect(() => {
		if (utilizationOverviewFetching) {
			setUtilizationOverviewDelayedFetching(utilizationOverviewFetching);
		} else {
			setTimeout(() => {
				setUtilizationOverviewDelayedFetching(utilizationOverviewFetching);
			}, 250);
		}
	}, [utilizationOverviewFetching, utilizationOverviewDelayedFetching, setUtilizationOverviewDelayedFetching]);

	// Dynamic font sizing
	const dynamicStyles = dynamicFontSizeStyles();
	const utilizationAxisStyles = dynamicStyles.generalAxisStyles;
	const timeAxisStyles = dynamicStyles.timeAxisStyles;
	const smallerAxisLabelStyle = dynamicStyles.smallerAxisLabelStyle;

	const exportData =
		facilityUtilizationDetails?.data?.map((v) => {
			const {
				year,
				month,
				in_primetime_minutes,
				primetime_turnover_minutes,
				non_primetime_minutes,
				available_minutes,
				utilization_percentage,
				total_case_volume,
			} = v;
			return {
				year: year,
				month: month,
				in_primetime: in_primetime_minutes,
				primetime_turnover: primetime_turnover_minutes,
				non_primetime: non_primetime_minutes,
				available_time: available_minutes,
				adjusted_utilization: utilization_percentage,
				case_volume: total_case_volume,
			};
		}) ?? [];

	const legend = [
		{
			label: 'Off Target',
			color: getColor('yellow'),
		},
		{
			label: 'On Target',
			color: getColor('green'),
		},
	];

	// Checks if printable page version, then print
	useEffect(() => {
		const generatePDF = async () => {
			// Get the desired element to be captured
			const overview = document.getElementById('overview');
			const details = document.getElementById('details');
			const active_filters = document.getElementById('active-filters');

			if (overview && details && active_filters) {
				// Create a new jsPDF instance
				const doc = new jsPDF('p', 'pt', 'letter');

				// Render the content in a canvas
				const canvas_1 = await html2canvas(overview, {
					scale: 2,
				});
				const canvas_2 = await html2canvas(details, {
					scale: 2,
				});
				const canvas_3 = await html2canvas(active_filters, {
					scale: 2,
				});

				// Add the canvas image to the PDF
				const imgData_1 = canvas_1.toDataURL('image/jpeg');
				const imgData_2 = canvas_2.toDataURL('image/jpeg');
				const imgData_3 = canvas_3.toDataURL('image/jpeg');

				const n_detail_rows = facilityUtilizationDetails?.data.length ?? 12;

				// Adding chart
				doc.addImage(imgData_1, 'PNG', 10, 10, 590, 290);

				// Adding details
				if (n_detail_rows > 23) {
					doc.addPage();
				}
				doc.addImage(imgData_2, 'PNG', 65, n_detail_rows > 23 ? 20 : 310, 500, Math.max(20 * n_detail_rows, 40));

				// Adding active filters
				doc.addPage();
				doc.addImage(imgData_3, 'PNG', 10, 10, 590, 770);

				// Save the generated PDF
				doc.save('facility-util.pdf');
			}
			window.close();
		};

		if (
			printable &&
			facilityUtilizationOverview &&
			facilityUtilizationDetails &&
			!(utilizationDetailsDelayedFetching && utilizationDetailsFetching)
		) {
			setTimeout(() => {
				generatePDF();
			}, 0);
		}
	}, [
		facilityUtilizationDetails,
		facilityUtilizationOverview,
		utilizationDetailsDelayedFetching,
		printable,
		skipRequest,
		utilizationDetailsFetching,
	]);
	return (
		<div>
			{!printable && <PageHeading>Facility Utilization</PageHeading>}

			{!printable && (
				<div className='flex justify-end pb-3'>
					<Drawer
						metadata={metadata}
						filtersAreDirty={filtersAreDirty}
						trigger={
							<Button sizeX='sm' sizeY='md' variant={'primary-ghost'} className='mr-2'>
								<span className='material-symbol'>filter_alt</span>
								Filters
							</Button>
						}
						quickActions={[
							{
								icon: 'undo',
								onClick: resetFilters,
								tooltipText: 'Discard unapplied filter changes',
								disabled: !filtersAreDirty,
							},
							{
								icon: 'restart_alt',
								onClick: clearFilters,
								tooltipText: 'Reset filters to default',
								disabled: !metadata.saved_at,
							},
						]}
						actionButtons={[
							{
								onClick: applyFilters,
								children: 'Apply',
								disabled: !filtersAreDirty,
							},
							{
								// eslint-disable-next-line @typescript-eslint/no-empty-function
								onClick: () => {},
								children: 'Views',
								disabled: false,
							},
						]}
					>
						<FilterFields
							fields={[
								'dateRange',
								'surgeons',
								'daysOfWeek',
								'serviceLines',
								'encounterTypes',
								'rooms',
								'procedures',
								'utilizationType',
								'turnoverTimeThreshold',
							]}
						/>
					</Drawer>
					<div>
						<ButtonStack>
							<Button sizeX='md' sizeY='md' variant='primary'>
								<ExportButton no_button={true} sizeX='md' sizeY='md' variant='primary' contents={exportData}>
									Export CSV
								</ExportButton>
							</Button>

							<Button
								sizeX='md'
								sizeY='md'
								variant='primary'
								onClick={() => {
									window.open(`/facility-utilization?printable=true&view_by=${viewBy}&view_by_label=${viewByLabel}`);
								}}
							>
								Export PDF
							</Button>
						</ButtonStack>
					</div>
				</div>
			)}

			{!printable ? (
				<div className='block'>
					{(utilizationOverviewDelayedFetching || skipRequest || filtersAreFetching) && !printable && (
						<LogoOverlay backgroundColor='white' />
					)}

					<Panel
						title={'Primetime ' + utilizationType?.applied + ' Facility Utilization'}
						tooltipContent={
							!printable
								? 'Use this visualization to understand overall utilization rates across various temporal categories. In general, higher  primetime utilization correlates with more efficient operating room usage during peak hours.'
								: undefined
						}
						goToHelpID='facilityutilization'
						isEmpty={facilityUtilizationDetails?.data?.length === 0}
						className={`${printable && 'pb-3 text-h4 border border-red-200 w-screen'}`}
						noHeader={printable}
					>
						{!printable && (
							<Select
								label='View By'
								options={[
									{ label: 'Day of Week', value: 'day_of_week' },
									{ label: 'Month', value: 'month' },
									{ label: 'Year', value: 'year' },
									{ label: 'Quarter', value: 'quarter' },
								]}
								sizeX='sm'
								onChange={(opt) => {
									if (opt) {
										dropDowns.update({ ...dropDowns, viewBy: opt });
										saveDropdown({ ...dropDowns, viewBy: opt });
									}
								}}
								value={{ label: viewByLabel, value: viewBy }}
							/>
						)}
						<div>
							<ChartLegend options={legend} />
						</div>
						<div className='max-w-screen-2xl m-auto'>
							<VictoryChart
								domainPadding={determineDomainPadding(numBars)}
								height={200}
								minDomain={{ y: 0 }}
								maxDomain={{ y: 105 }}
								padding={{ top: 30, bottom: 35, right: 30, left: 40 }}
							>
								{/* Y-Axis */}
								<VictoryAxis
									tickFormat={(t) => `${Math.round(t)}%`}
									sortOrder='descending'
									dependentAxis
									label='Utilization %'
									padding={{ left: 20 }}
									style={timeAxisStyles}
								/>
								{/* X-Axis */}
								<VictoryAxis style={utilizationAxisStyles} standalone={false} label={viewByLabel} />
								<VictoryBar
									style={{
										data: {
											fill: ({ datum }) => {
												return datum && datum.y < target ? getColor('yellow') : getColor('green');
											},
										},
									}}
									// style={{ data: { fill: ({ datum }) => determineColor(datum.y) } }}
									data={chartData}
									cornerRadius={2}
									barWidth={determineBarWidth(numBars)}
									labels={({ datum }) => datum.y + '%'}
									labelComponent={<CustomLabel />}
								/>
								<VictoryLine
									y={() => target}
									style={{
										data: { stroke: '#000033', strokeWidth: 0.2, strokeDasharray: 1 },
										parent: { border: '0.5px solid #ccc' },
									}}
								/>
								<VictoryAxis
									dependentAxis
									orientation='right'
									tickValues={[target]}
									tickFormat={(targetPercent) => [`${Math.round(targetPercent)}%`, 'Utilization', 'Target']}
									style={{ axis: { strokeWidth: 0 }, tickLabels: { padding: 0 } }}
									tickLabelComponent={<VictoryLabel lineHeight={[1.75, 1.25, 1.25]} style={smallerAxisLabelStyle} />}
									animate
								/>
							</VictoryChart>
						</div>
					</Panel>
				</div>
			) : (
				<div id='overview'>
					{printable && (
						<div className='flex justify-between'>
							<div className='flex text-center'>
								<div className='pt-5 pl-8'>
									<img className='h-12 w-12 relative -left-[2px]' src={Logo} alt='Merlin' />
								</div>
								<p className='pt-6 pl-4 w-16 whitespace-nowrap text-h5 font-semibold'>
									{'Primetime ' + utilizationType?.applied + ' Facility Utilization'}
								</p>
							</div>
							<div className='flex'>
								<div className='px-4 py-2 bg-blue-200 text-p1 w-fit rounded-md mt-6 self-center mr-6'>
									{`${format(dateRange.applied.startDate, 'M/d/yyyy')} - ${format(dateRange.applied.endDate, 'M/d/yyyy')}`}
								</div>
								<div className='px-4 py-2 bg-blue-200 text-p1 w-fit rounded-md mt-6 self-center'>
									{`${facility?.intraop_facility_name_alias ? facility.intraop_facility_name_alias : facility?.name} - ${
										system?.name
									}`}
								</div>
							</div>
						</div>
					)}
					<VictoryChart
						domainPadding={determineDomainPadding(numBars)}
						height={200}
						minDomain={{ y: 0 }}
						maxDomain={{ y: 105 }}
						padding={{ top: 30, bottom: 35, right: 30, left: 40 }}
					>
						{/* Y-Axis */}
						<VictoryAxis
							tickFormat={(t) => `${Math.round(t)}%`}
							sortOrder='descending'
							dependentAxis
							label='Utilization %'
							padding={{ left: 20 }}
							style={timeAxisStyles}
						/>
						{/* X-Axis */}
						<VictoryAxis style={utilizationAxisStyles} standalone={false} label={viewByLabel} />
						<VictoryBar
							style={{
								data: {
									fill: ({ datum }) => {
										return datum && datum.y < target ? getColor('yellow') : getColor('green');
									},
								},
							}}
							// style={{ data: { fill: ({ datum }) => determineColor(datum.y) } }}
							data={chartData}
							cornerRadius={2}
							barWidth={determineBarWidth(numBars)}
							labels={({ datum }) => datum.y + '%'}
							labelComponent={<CustomLabel />}
						/>
						<VictoryLine
							y={() => target}
							style={{
								data: { stroke: '#000033', strokeWidth: 0.2, strokeDasharray: 1 },
								parent: { border: '0.5px solid #ccc' },
							}}
						/>
						<VictoryAxis
							dependentAxis
							orientation='right'
							tickValues={[target]}
							tickFormat={(targetPercent) => [`${Math.round(targetPercent)}%`, 'Utilization', 'Target']}
							style={{ axis: { strokeWidth: 0 }, tickLabels: { padding: 0 } }}
							tickLabelComponent={<VictoryLabel lineHeight={[1.75, 1.25, 1.25]} style={smallerAxisLabelStyle} />}
							animate
						/>
					</VictoryChart>
				</div>
			)}
			<div className={`mt-9 relative ${printable && 'm-auto w-[80em]'}`} id='details'>
				{(utilizationDetailsDelayedFetching || skipRequest || filtersAreFetching) && !printable && (
					<LogoOverlay backgroundColor='white' />
				)}

				<DataTable
					title='Facility Utilization Details'
					tooltipContent={
						'This table provides a breakdown of the components that factor into facility utilization, like in primetime minutes, turnover, and available time. It also includes other helpful values like non primetime minutes and case volume, for example.'
					}
					minimalStyle={printable}
					goToHelpID='facilityutilization'
					headerContentCenterAlignment='left'
					disablePagination={printable}
					disableRowCounter={printable}
					columns={[
						{
							header: 'Year',
							accessorKey: 'year',
							accessorFn: (row) => `${row.year.toString()}`,
						},
						{
							header: 'Month',
							accessorFn: (row) => `${row?.month && convertMonthNumericToString(row?.month, 'short')}`,
							cell: ({ row }) => `${row?.original?.month && convertMonthNumericToString(row?.original?.month, 'short')}`,
							sortingFn: (rowA, rowB) => {
								const a = rowA.original.month ? new Date(2001, rowA.original.month) : 1;
								const b = rowB.original.month ? new Date(2001, rowB.original.month) : 1;
								return b < a ? 1 : -1;
							},
						},
						{
							header: 'In Primetime',
							accessorKey: 'in_primetime_minutes',
							cell: ({ row }) => `${row.original.in_primetime_minutes} min`,
							enableGlobalFilter: false,
						},
						{
							header: 'Primetime Turnover',
							accessorKey: 'primetime_turnover_minutes',
							cell: ({ row }) => `${row.original.primetime_turnover_minutes} min`,
							enableGlobalFilter: false,
						},
						{
							header: 'Non Primetime',
							accessorKey: 'non_primetime_minutes',
							cell: ({ row }) => `${row.original.non_primetime_minutes} min`,
							enableGlobalFilter: false,
						},
						{
							header: 'Available Time',
							accessorKey: 'available_minutes',
							cell: ({ row }) => `${row.original.available_minutes} min`,
							enableGlobalFilter: false,
						},
						{
							header: `${utilizationType?.applied} Utilization`,
							accessorKey: 'utilization_percentage',
							cell: ({ row }) => `${row.original.utilization_percentage}%`,
							enableGlobalFilter: false,
						},
						{ header: 'Case Volume', accessorKey: 'total_case_volume', enableGlobalFilter: false },
					]}
					data={tableData}
				/>
			</div>
			{printable && (
				<div id={`active-filters`} className='h-[125em] pl-12 pt-12 pr-12'>
					<h1 className='text-h2 font-bold'>Active Filters</h1>
					{[
						{
							name: 'Date Range',
							context: `${format(dateRange.applied.startDate, 'M/d/yyyy')} - ${format(dateRange.applied.endDate, 'M/d/yyyy')}`,
							type: 'static',
						},
						{ name: 'Utilization Type', context: `${utilizationType.applied}`, type: 'static' },
						{ name: 'Turnover Time Threshold', context: `${turnoverTimeThreshold.applied} mins`, type: 'static' },
						{ name: 'Included Surgeons', context: surgeons.applied, type: 'array' },
						{ name: 'Included Rooms', context: rooms.applied, type: 'array' },
						{ name: 'Included Encounter Types', context: encounterTypes.applied, type: 'array' },
						{ name: 'Included Service Lines', context: serviceLines.applied, type: 'array' },
						{ name: 'Included Days of Week', context: daysOfWeek.applied, type: 'array' },
					].map((filter, i) => {
						return (
							<div key={i} className='flex flex-row flex-wrap items-center'>
								<h3 className='px-4 py-2 bg-blue-200 text-p1 w-fit rounded-md mt-6 self-center mr-6'>{filter.name}</h3>

								{typeof filter.context == 'object' ? (
									<div className='flex flex-row flex-wrap'>
										{filter.context.slice(0, 40).map((option, i) => (
											<div key={i} className='px-4 py-2 mr-2 border border-blue-300 text-p2 w-fit rounded-md mt-6 self-center'>
												{typeof option == 'string' ? option : option.name}
											</div>
										))}
										{filter.context.slice(0, 40).length === 40 && (
											<div className='px-4 py-2 mr-2 font-semibold text-p2 w-fit rounded-md mt-6 self-center'>{`+ ${
												filter.context.length - filter.context.slice(0, 40).length
											} ....`}</div>
										)}
									</div>
								) : (
									<p className='pt-2'>{`${filter.context}`}</p>
								)}
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
}

function transformOverviewData(data: FacilityUtilizationOverviewResponse | undefined) {
	if (!data) return { dataArr: [], numBars: 0 };

	const dateTypeUtilArr = data.facility_utilization_overview_data.reduce<FacilityUtilizationChartDatum[]>(
		(carry, row) => {
			if (data.group_by === 'day_of_week' && row.day_of_week) {
				carry.push({
					x: convertDOWNumericToString(row.day_of_week, 'short'),
					y: row.utilization_percentage,
					available_minutes: row.available_minutes,
					primetime_minutes: row.primetime_minutes,
					turnover_minutes: row.turnover_minutes,
				});
			} else if (data.group_by === 'month' && row.month && row.year) {
				carry.push({
					x: `${convertMonthNumericToString(row.month, 'short')} '${row.year.toString().slice(-2)}`,
					y: row.utilization_percentage,
					available_minutes: row.available_minutes,
					primetime_minutes: row.primetime_minutes,
					turnover_minutes: row.turnover_minutes,
				});
			} else if (data.group_by === 'quarter' && row.quarter && row.year) {
				carry.push({
					x: `Q${row.quarter} '${row.year.toString().slice(-2)}`,
					y: row.utilization_percentage,
					available_minutes: row.available_minutes,
					primetime_minutes: row.primetime_minutes,
					turnover_minutes: row.turnover_minutes,
				});
			} else if (data.group_by === 'year' && row.year) {
				carry.push({
					x: `${row.year}`,
					y: row.utilization_percentage,
					available_minutes: row.available_minutes,
					primetime_minutes: row.primetime_minutes,
					turnover_minutes: row.turnover_minutes,
				});
			}

			return carry;
		},
		[]
	);

	return {
		dataArr: dateTypeUtilArr,
		numBars: dateTypeUtilArr.length,
	};
}

function determineColor(datum: number) {
	//might need to get official colors here
	if (datum <= 30) {
		//darkest blue
		return '#003399';
	} else if (datum > 30 && datum < 60) {
		// dark blue
		return '#0066cc';
	} else {
		//lighter blue
		return '#6699ff';
	}
}

export default FacilityUtilization;
