import { NavigateFunction, useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryLabel, VictoryLine, VictoryPie } from 'victory';
import { format } from 'date-fns';
import ReactToPrint from 'react-to-print';

import {
	AddOnsState,
	BlockNamesState,
	DateRangeState,
	DaysOfWeekState,
	FilterContextField,
	ProcedureOptionsState,
	RangeFilterFieldState,
	useFilters,
	useToast,
	UtilizationTypeState,
} from 'context';
import { BlockPatternPreviewObject, getQueryError, handleApiResponse, useAppSelector, useSystem } from 'store';
import {
	BlockScheduleItem,
	BlockScorecardResponseItem,
	ReleaseLogItem,
	useGetBlockScorecardQuery,
	useSetVoluntaryReleaseMutation,
	useUndoVoluntaryReleaseMutation,
} from 'store/services/BlockScorecardService';
import {
	Button,
	ButtonStack,
	ChartLegend,
	DataTable,
	Drawer,
	ExportButton,
	FilterFields,
	LogoOverlay,
	PageHeading,
	Panel,
	Select,
	Tooltip,
} from 'components';
import { determineBarWidth, determineDomainPadding, fontFamily, getColor, shortDOWToNumber } from 'utils';
import { PrintableBlockScorecard, ReleaseDialog } from 'pages/Block';
import image from 'assets/images/empty.svg';
import Logo from 'assets/images/branding/logo.svg';
import { Routes } from 'models';

export function BlockScorecard() {
	const { selectedSystem, selectedFacility } = useAppSelector((state) => state.userState);
	const { data: userData } = useSystem();
	const system = userData?.healthsystems.find((h) => h.id === selectedSystem);

	const current_facility = userData?.facilities?.find((f) => f.id === selectedFacility);

	const blockUtilPercentage = current_facility?.block_utilization_target ?? 70;
	const { createToast } = useToast();
	const navigate = useNavigate();
	const [renderPrintable, setRenderPrintable] = useState(false);
	const {
		dateRange,
		blockNames,
		daysOfWeek,
		utilizationType,
		addOns,
		turnoverTimeThreshold,
		procedures,
		abandonedDaysToggle,
		dropDowns,
		saveDropdown,
		resetFilters,
		applyFilters,
		clearFilters,
		filtersAreDirty,
		metadata,
		currentPageLoaded,
		filtersAreFetching,
	} = useFilters();

	// There is sometimes a delay in our filters when a user switches pages
	// (which is why we check if currentPageLoaded is equal to our current page),
	// To account for the delay, we tell our RTK Query to skip until we set skipRequest to false.
	const [skipRequest, setSkipRequest] = useState(true);
	useEffect(() => {
		setTimeout(() => {
			if (currentPageLoaded === '/block-scorecard') {
				setSkipRequest(false);
			}
		}, 0);
	}, [currentPageLoaded]);

	const groupBy = dropDowns.groupBy.value !== 'undefined' ? dropDowns.groupBy.value : 'day_of_week';
	const groupByLabel = dropDowns.groupBy.label !== 'undefined' ? dropDowns.groupBy.label : 'Day of Week';

	const setGroupBy = (arg: { label: string; value: string }) => {
		dropDowns.update({ ...dropDowns, groupBy: arg });
		saveDropdown({ ...dropDowns, groupBy: arg });
	};

	const { data, isFetching } = useGetBlockScorecardQuery(
		{
			facility_id: selectedFacility,
			healthsystem_id: selectedSystem,
			block_id: blockNames?.applied.map((b) => b.id),
			groupBy: groupBy,
			filters: {
				days_of_week: daysOfWeek.applied,
				utilization_type: utilizationType.applied,
				procedures: procedures?.applied.map((procedure) => procedure.name),
				turnover_threshold: turnoverTimeThreshold.applied,
				start_date: format(dateRange?.applied.startDate, 'M/d/yyyy'),
				end_date: format(dateRange?.applied.endDate, 'M/d/yyyy'),
				abandoned_days_toggle: abandonedDaysToggle.applied,
			},
		},
		{
			skip: blockNames.applied.length < 1 || skipRequest || filtersAreFetching,
		}
	);

	const [releaseBlocks] = useSetVoluntaryReleaseMutation();

	const submitRelease = async (blocks: BlockScheduleItem[]) => {
		const response = await releaseBlocks({
			healthsystem_id: selectedSystem,
			facility_id: selectedFacility,
			data: blocks,
		});

		handleApiResponse(response, {
			success: (payload) => {
				createToast({
					title: 'Blocks released successfully.',
					variant: 'success',
				});
			},
			error: (payload) => {
				createToast({
					title: getQueryError(payload),
					variant: 'error',
				});
			},
		});
	};

	const [undoReleaseBlocks] = useUndoVoluntaryReleaseMutation();
	const submitUndoRelease = async (blocks: ReleaseLogItem[]) => {
		const response = await undoReleaseBlocks({ healthsystem_id: selectedSystem, data: blocks });

		handleApiResponse(response, {
			success: (payload) => {
				createToast({
					title: 'Block releases undone.',
					variant: 'success',
				});
			},
			error: (payload) => {
				createToast({
					title: getQueryError(payload),
					variant: 'error',
				});
			},
		});
	};

	const excludedString = abandonedDaysToggle.applied === 'Excluded' ? true : false;

	const myRef = useRef<HTMLDivElement>(null);

	return (
		<>
			<PageHeading>
				{blockNames?.applied.length === 0
					? ''
					: blockNames?.applied.length === 1
					? blockNames?.applied[0].name
					: 'Block Scorecard'}
			</PageHeading>

			<div className='flex justify-between pb-3 items-center'>
				{blockNames.applied.length === 1 && data && (
					<BlockPreviewHeader data={(data.length > 0 ? data[0].block_schedule : undefined) ?? []} />
				)}
				{abandonedDaysToggle.selected === 'Excluded' && (
					<div className='bg-yellow-100 flex p-2 rounded-md mt-2 justify-between py-3'>
						<span className='material-symbols-outlined text-yellow-500 pr-2'>warning</span>
						<p className='text-p3 text-gray-500'>
							<strong>Note:</strong> Utilization does not include abandoned days and may be higher than expected
						</p>
					</div>
				)}
				<div className='flex w-full justify-end'>
					<Drawer
						metadata={metadata}
						filtersAreDirty={filtersAreDirty}
						trigger={
							<Button sizeX='sm' sizeY='md' variant={'primary-ghost'} className='mr-3'>
								<span className='material-symbol'>filter_alt</span>
								Filters
							</Button>
						}
						quickActions={[
							{
								icon: 'undo',
								onClick: resetFilters,
								tooltipText: 'Discard unapplied filter changes',
								disabled: !filtersAreDirty,
							},
							{
								icon: 'restart_alt',
								onClick: clearFilters,
								tooltipText: 'Reset filters to default',
								disabled: !metadata.saved_at,
							},
						]}
						actionButtons={[
							{
								onClick: applyFilters,
								children: 'Apply',
								disabled: !filtersAreDirty,
							},
							{
								// eslint-disable-next-line @typescript-eslint/no-empty-function
								onClick: () => {},
								children: 'Views',
								disabled: false,
							},
						]}
					>
						<FilterFields
							fields={[
								'dateRange',
								'blockNames',
								'daysOfWeek',
								'utilizationType',
								'turnoverTimeThreshold',
								'procedures',
								'abandonedDaysToggle',
							]}
							dateRangeLimit={1}
						/>
					</Drawer>
					{data && !isFetching && (
						<ButtonStack onOpenChange={() => setRenderPrintable(true)}>
							<Button sizeX='md' sizeY='md' variant='primary'>
								<ExportButton
									no_button={true}
									sizeX='md'
									sizeY='md'
									variant='primary'
									contents={
										data?.map((item, i) => item.block_details).reduce((accumulator, value) => accumulator.concat(value), []) ?? []
									}
								>
									Export CSV
								</ExportButton>
							</Button>

							<Button sizeX='md' sizeY='md' variant='primary'>
								<ReactToPrint
									trigger={() => {
										// NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
										// to the root node of the returned component as it will be overwritten.
										return <p className='text-white font-regular text-left'>Export PDF</p>;
									}}
									content={() => {
										return myRef.current;
									}}
								/>
							</Button>
						</ButtonStack>
					)}
				</div>
			</div>

			{(isFetching || skipRequest || filtersAreFetching) && <LogoOverlay backgroundColor='white' />}

			{blockNames.applied.length === 0 && (
				<React.Fragment>
					<img className='h-16 w-16 block m-auto mt-32' src={Logo} alt='Merlin' />
					<p className='text-p1 text-center mt-3'>Please select a block using the filters button.</p>
				</React.Fragment>
			)}

			{blockNames.applied.length > 1 && data && (
				<DataTable
					data={data ?? []}
					headerContentRight={
						<ReleaseDialog
							facility_id={selectedFacility}
							healthsystem_id={selectedSystem}
							selectedBlock={blockNames.applied[0]}
							selectableBlocks={true}
							blockOptions={blockNames.all}
							requestRelease={(release) => {
								submitRelease(release);
							}}
							requestUndoRelease={(release) => {
								submitUndoRelease(release);
							}}
							filters={{
								days_of_week: daysOfWeek.applied,
								utilization_type: utilizationType.applied,
								add_ons: addOns.applied,
								turnover_threshold: turnoverTimeThreshold.applied,
								start_date: format(dateRange?.applied.startDate, 'M/d/yyyy'),
								end_date: format(dateRange?.applied.endDate, 'M/d/yyyy'),
							}}
						>
							<Button sizeX='sm' sizeY='sm' variant={'secondary'} className='mr-3'>
								Release
							</Button>
						</ReleaseDialog>
					}
					columns={[
						{
							accessorKey: 'block_name',
							header: 'Block Name',
							enableGlobalFilter: false,
							meta: {
								headerClass: 'w-36',
							},
						},
						{
							accessorKey: 'aggregate_util_percentage',
							header: 'Utilization',
							cell: ({ row }) => {
								let color =
									row.original.aggregate_util_percentage >= data[0].utilization_target_percent
										? 'text-green-500 text-center'
										: 'text-red-500 text-center ';

								const isValueGiven = row.original.aggregate_util_percentage >= 0;

								if (!isValueGiven) {
									color = 'text-gray-300 text-center';
								}
								return <p className={color}>{isValueGiven ? `${row.original.aggregate_util_percentage}%` : '---'}</p>;
							},
							enableGlobalFilter: false,
						},
						{
							accessorKey: 'allocated',
							header: 'Allocated',
							cell: ({ row }) => {
								return `${row.original.release_patterns.filter((item: { x: string }) => item.x === 'Allocated')[0].y}m`;
							},
							sortingFn: (rowA, rowB) => {
								const a = rowA.original.release_patterns.filter((item: { x: string }) => item.x === 'Allocated')[0].y;
								const b = rowB.original.release_patterns.filter((item: { x: string }) => item.x === 'Allocated')[0].y;
								return b > a ? 1 : -1;
							},
						},
						{
							header: 'In-Block',
							accessorKey: 'in_block',
							enableGlobalFilter: false,
							cell: ({ row }) => {
								return (
									<p className='whitespace-nowrap'>{`${
										row.original.release_patterns.filter((item: { x: string }) => item.x === 'In Block')[0].y
									}m`}</p>
								);
							},
							sortingFn: (rowA, rowB) => {
								const a = rowA.original.release_patterns.filter((item: { x: string }) => item.x === 'In Block')[0].y;
								const b = rowB.original.release_patterns.filter((item: { x: string }) => item.x === 'In Block')[0].y;
								return b > a ? 1 : -1;
							},
						},
						{
							header: 'Out-Block',
							accessorKey: 'out_block',
							enableGlobalFilter: false,
							cell: ({ row }) => {
								return (
									<p className='whitespace-nowrap'>{`${
										row.original.release_patterns.filter((item: { x: string }) => item.x === 'Out Block')[0].y
									}m`}</p>
								);
							},
							sortingFn: (rowA, rowB) => {
								const a = rowA.original.release_patterns.filter((item: { x: string }) => item.x === 'Out Block')[0].y;
								const b = rowB.original.release_patterns.filter((item: { x: string }) => item.x === 'Out Block')[0].y;
								return b > a ? 1 : -1;
							},
						},
						{
							header: 'Block Turnover',
							accessorKey: 'block_turnover',
							enableGlobalFilter: false,
							cell: ({ row }) =>
								`${row.original.release_patterns.filter((item: { x: string }) => item.x === 'Block Turnover')[0].y}m`,
							meta: {
								headerClass: 'whitespace-pre-line',
							},
							sortingFn: (rowA, rowB) => {
								const a = rowA.original.release_patterns.filter((item: { x: string }) => item.x === 'Block Turnover')[0].y;
								const b = rowB.original.release_patterns.filter((item: { x: string }) => item.x === 'Block Turnover')[0].y;
								return b > a ? 1 : -1;
							},
						},
						{
							header: 'Voluntary Release',
							accessorKey: 'voluntary_release',
							enableGlobalFilter: false,
							cell: ({ row }) => {
								return (
									<p>
										{row.original.release_patterns.filter((item: { x: string }) => item.x === 'Voluntary Release')[0].y >= 0
											? `${row.original.release_patterns.filter((item: { x: string }) => item.x === 'Voluntary Release')[0].y}m`
											: 'nullified'}
									</p>
								);
							},
							meta: {
								headerClass: 'whitespace-pre-line',
							},
							sortingFn: (rowA, rowB) => {
								const a = rowA.original.release_patterns.filter((item: { x: string }) => item.x === 'Voluntary Release')[0].y;
								const b = rowB.original.release_patterns.filter((item: { x: string }) => item.x === 'Voluntary Release')[0].y;
								return b > a ? 1 : -1;
							},
						},
						{
							header: 'Automatic Release',
							accessorKey: 'automatic_release',
							enableGlobalFilter: false,
							cell: ({ row }) => {
								return (
									<p>
										{row.original.release_patterns.filter((item: { x: string }) => item.x === 'Automatic Release')[0].y >= 0
											? `${row.original.release_patterns.filter((item: { x: string }) => item.x === 'Automatic Release')[0].y}m`
											: 'nullified'}
									</p>
								);
							},
							meta: {
								headerClass: 'whitespace-pre-line',
							},
							sortingFn: (rowA, rowB) => {
								const a = rowA.original.release_patterns.filter((item: { x: string }) => item.x === 'Automatic Release')[0].y;
								const b = rowB.original.release_patterns.filter((item: { x: string }) => item.x === 'Automatic Release')[0].y;
								return b > a ? 1 : -1;
							},
						},
					]}
					title={'Block Scorecards'}
					rowSubview={(row) => {
						if (row.getIsSelected()) {
							return (
								<BlockScorecardSubset
									nested={true}
									data={{
										block_name: row.original.block_name,
										start_date: row.original.start_date,
										end_date: row.original.end_date,
										utilization_target_percent: row.original.utilization_target_percent,
										aggregate_util_percentage: row.original.aggregate_util_percentage,
										aggregate_block_utilization_dow: row.original.aggregate_block_utilization_dow,
										release_patterns: row.original.release_patterns,
										block_details: row.original.block_details,
										surgeon_block_minutes_contributions: row.original.surgeon_block_minutes_contributions,
										block_schedule: row.original.block_schedule,
									}}
									selectedSystem={undefined}
									selectedFacility={undefined}
									navigate={navigate}
									blockNames={blockNames}
									procedures={procedures}
									blockUtilPercentage={blockUtilPercentage}
									daysOfWeek={daysOfWeek}
									utilizationType={utilizationType}
									addOns={addOns}
									turnoverTimeThreshold={turnoverTimeThreshold}
									dateRange={dateRange}
									submitRelease={function (blocks: BlockScheduleItem[]): void {
										submitRelease(blocks);
									}}
									submitUndoRelease={function (blocks: ReleaseLogItem[]): void {
										submitUndoRelease(blocks);
									}}
									healthsystem_name={system?.name}
									facility_name={current_facility?.name}
									groupByLabel={groupByLabel}
									groupBy={groupBy}
									setGroupBy={setGroupBy}
									excludedString={excludedString}
								/>
							);
						} else {
							return null;
						}
					}}
				/>
			)}

			{blockNames.applied.length === 1 && data && data.length > 0 && (
				<BlockScorecardSubset
					nested={false}
					data={{
						block_name: data?.[0].block_name,
						start_date: data?.[0].start_date,
						end_date: data?.[0].end_date,
						utilization_target_percent: data?.[0].utilization_target_percent,
						aggregate_util_percentage: data?.[0].aggregate_util_percentage,
						aggregate_block_utilization_dow: data?.[0].aggregate_block_utilization_dow,
						release_patterns: data?.[0].release_patterns,
						block_details: data?.[0].block_details,
						surgeon_block_minutes_contributions: data?.[0].surgeon_block_minutes_contributions,
						block_schedule: data?.[0].block_schedule,
					}}
					selectedSystem={system?.id}
					selectedFacility={current_facility?.id}
					blockNames={blockNames}
					navigate={navigate}
					blockUtilPercentage={blockUtilPercentage}
					procedures={procedures}
					daysOfWeek={daysOfWeek}
					utilizationType={utilizationType}
					addOns={addOns}
					turnoverTimeThreshold={turnoverTimeThreshold}
					dateRange={dateRange}
					submitRelease={function (blocks: BlockScheduleItem[]): void {
						submitRelease(blocks);
					}}
					submitUndoRelease={function (blocks: ReleaseLogItem[]): void {
						submitUndoRelease(blocks);
					}}
					healthsystem_name={system?.name}
					facility_name={current_facility?.name}
					setGroupBy={setGroupBy}
					groupBy={groupBy}
					groupByLabel={groupByLabel}
					excludedString={excludedString}
				/>
			)}

			{renderPrintable && (
				<div className='hidden'>
					<PrintableBlockScorecard
						utilizationType={utilizationType}
						turnoverTimeThreshold={turnoverTimeThreshold}
						data={data}
						healthsystem_name={system?.name}
						facility_name={current_facility?.name}
						date={dateRange}
						groupByLabel={groupByLabel}
						ref={myRef}
						blockUtilPercentage={blockUtilPercentage}
					/>
				</div>
			)}
		</>
	);
}

export function BlockScorecardSubset({
	data,
	selectedSystem,
	selectedFacility,
	blockNames,
	navigate,
	blockUtilPercentage,
	daysOfWeek,
	utilizationType,
	procedures,
	addOns,
	turnoverTimeThreshold,
	dateRange,
	submitRelease,
	submitUndoRelease,
	nested,
	excludedString,
	groupByLabel,
	groupBy,
	setGroupBy,
}: {
	data: BlockScorecardResponseItem;
	selectedSystem: number | undefined;
	selectedFacility: number | undefined;
	navigate: NavigateFunction;
	blockUtilPercentage: number;
	procedures: FilterContextField<ProcedureOptionsState>;
	blockNames: FilterContextField<BlockNamesState>;
	daysOfWeek: FilterContextField<DaysOfWeekState>;
	utilizationType: FilterContextField<UtilizationTypeState>;
	addOns: FilterContextField<AddOnsState>;
	turnoverTimeThreshold: FilterContextField<RangeFilterFieldState>;
	dateRange: FilterContextField<DateRangeState>;
	submitRelease: (blocks: BlockScheduleItem[]) => void;
	submitUndoRelease: (blocks: ReleaseLogItem[]) => void;
	healthsystem_name: string | undefined;
	facility_name: string | undefined;
	nested?: boolean;
	excludedString: boolean;
	groupByLabel: string;
	groupBy: string;
	setGroupBy: (arg0: { label: string; value: string }) => void;
}) {
	let util_arr = data?.block_details.map((row) => row.utilization) ?? [1];

	if (util_arr.length < 1) {
		util_arr = [1];
	}

	const util_target_met =
		util_arr?.filter((x) => x >= 0).reduce((a, b) => a + b, 0) / util_arr?.filter((x) => x >= 0).length >=
		(data?.utilization_target_percent ?? 1);

	const legend = [
		{
			label: 'Needs Attention',
			color: getColor('red'),
		},
		{
			label: 'On Target',
			color: getColor('green'),
		},
		{
			label: 'Off Target',
			color: getColor('yellow'),
		},
	];
	const dataMaxY = (data && Math.ceil(Math.max(...data.aggregate_block_utilization_dow.map((d) => d.y)) + 10)) ?? 100;
	return (
		<div className={nested ? 'w-[80em]' : ''}>
			{data && (
				<>
					<Panel title='Overview' headerContentCenterAlignment='left'>
						<div className='flex justify-around py-2'>
							<div className='px-8 flex flex-col justify-between'>
								<div className='flex items-center justify-center gap-2 pt-3'>
									<span className='font-semibold'>Aggregate Block Utilization</span>
									<Tooltip content='Overall block utilization performance for the time period specified in the filter pane. In general, higher block utilization performance correlates with more efficient resource usage and greater scheduling predictability.'>
										<div
											className='material-symbol-sm text-blue-500'
											onClick={() => navigate(Routes.HELP_FAQS + `?goToId=${'commonDefinitions'}`)}
										>
											info
										</div>
									</Tooltip>
								</div>
								{data.aggregate_util_percentage > 0 ? (
									<div className='m-auto'>
										<svg width={200} height={200}>
											<VictoryPie
												innerRadius={80}
												width={200}
												height={200}
												radius={95}
												standalone={false}
												data={[data.aggregate_util_percentage, 100 - data.aggregate_util_percentage]}
												colorScale={['rgb(157, 196, 251)', 'black']}
												style={{ labels: { opacity: 0 } }}
												animate={true}
											/>
											<VictoryLabel
												textAnchor='middle'
												style={{ fontSize: 32, fontFamily: 'inter' }}
												x={100}
												y={100}
												text={`${data.aggregate_util_percentage}%`}
											/>
										</svg>
									</div>
								) : (
									<div className='flex flex-col items-center'>
										<div className='flex flex-col w-full items-center gap-6 p-8 text-p2 text-gray-600 pt-20'>
											<img className='w-44' alt='An empty clipboard' src={image} />
											{'There are no data to display.'}
										</div>
									</div>
								)}
							</div>

							<div className='justify-self-center w-3/5'>
								<div className='flex justify-between gap-2'>
									<p className='px-3 text-left mr-2 py-2 pb-2 flex items-center justify-left gap-2'>
										<span className='font-semibold'>{`${data?.block_name} Block Utilization by Day of Week`}</span>
										<Tooltip content={`Use this visualization to track the overall block utilization trend.`}>
											<div
												className='material-symbol-sm text-blue-500'
												onClick={() => navigate(Routes.HELP_FAQS + `?goToId=${'commonDefinitions'}`)}
											>
												info
											</div>
										</Tooltip>
									</p>
									{data.aggregate_block_utilization_dow.length > 0 && (
										<Select
											label=''
											sizeY='sm'
											sizeX='sm'
											options={[
												{
													label: 'Day of Week',
													value: 'day_of_week',
												},
												{
													label: 'Month',
													value: 'month',
												},
												{
													label: 'Quarter',
													value: 'quarter',
												},
											]}
											defaultValue={{
												label: groupByLabel,
												value: groupBy,
											}}
											disabled={!nested ? false : true}
											onChange={(option) => {
												if (option) {
													setGroupBy(option);
												}
											}}
										/>
									)}
								</div>
								{!data.aggregate_block_utilization_dow.every((item) => item.y === 0) ? (
									<div className='pt-4'>
										<ChartLegend options={legend} />
										<VictoryChart
											height={window.innerHeight / 2.4}
											width={window.innerWidth / 2}
											standalone={true}
											domainPadding={determineDomainPadding(data.aggregate_block_utilization_dow?.length)}
											maxDomain={{ y: dataMaxY > 100 ? dataMaxY : 100 }}
											padding={{ top: 20, bottom: 40, left: 35, right: 90 }}
										>
											<VictoryLine
												y={() => 85}
												style={{ data: { strokeDasharray: 2, strokeWidth: 1, stroke: 'hsl(211 36% 59%)' } }}
												animate
											/>
											<VictoryAxis
												dependentAxis
												tickFormat={(t) => `${t}%`}
												style={{
													axis: { stroke: 'hsl(0 0% 90%)' },
													grid: { stroke: 'hsl(0 0% 90%)' },
													tickLabels: { fontFamily: 'Inter', fontSize: 10 },
												}}
											/>
											<VictoryAxis
												style={{
													axis: { stroke: 'hsl(0 0% 90%)' },
													tickLabels: {
														fontFamily: 'Inter',
														fontSize: 12,
													},
												}}
												tickLabelComponent={
													<VictoryLabel
														data={data.aggregate_block_utilization_dow}
														style={{
															fontFamily: 'Inter',
															fontSize: 14,
														}}
													/>
												}
											/>
											<VictoryLine
												y={() => blockUtilPercentage}
												style={{ data: { strokeDasharray: 2, strokeWidth: 1, stroke: 'hsl(211 36% 59%)' } }}
												animate
											/>
											<VictoryBar
												barWidth={determineBarWidth(data.aggregate_block_utilization_dow?.length)}
												style={{
													data: {
														// Use RGB values for the bar color to trigger a more natural color space interpolation while
														// animating, as HSL hue rotation tends to feel too extreme.
														// https://github.com/d3/d3-interpolate#color-spaces
														fill: ({ datum }) => {
															const value = datum.y;
															if (value < 40) {
																return getColor('red');
															} else if ((value >= 40 && value < blockUtilPercentage) || value > 85) {
																return getColor('yellow');
															} else {
																return getColor('green');
															}
														},
													},
													labels: {
														fontFamily: 'Inter',
													},
												}}
												cornerRadius={{ top: 4 }}
												data={data.aggregate_block_utilization_dow}
												labels={({ datum }) => `${Math.round(datum.y)}%`}
												animate
											/>

											<VictoryAxis
												dependentAxis
												orientation='right'
												tickValues={[(blockUtilPercentage + 85) / 2]}
												tickFormat={() => [`${blockUtilPercentage}% - 85%`, `Target Range`]}
												style={{ axis: { strokeWidth: 0 }, tickLabels: { padding: 10 } }}
												tickLabelComponent={
													<VictoryLabel
														lineHeight={[1.3, 1.3]}
														style={[
															{ fontFamily: 'Inter', fontSize: 12, fontWeight: 600 },
															{ fontFamily: 'Inter', fontSize: 10 },
														]}
													/>
												}
												animate
											/>
										</VictoryChart>
									</div>
								) : (
									<div className='flex flex-col items-center'>
										<div className='flex flex-col w-full items-center gap-6 p-8 text-p2 text-gray-600 pt-20'>
											<img className='w-44' alt='An empty clipboard' src={image} />
											{'There are no data to display.'}
										</div>
									</div>
								)}
							</div>
						</div>
					</Panel>
					<div className='py-4'></div>
					<Panel
						title={''}
						noHeader={true}
						isEmpty={data.surgeon_block_minutes_contributions.length > 0 || data.release_patterns.length > 0 ? false : true}
						headerContentCenterAlignment='left'
					>
						<div className='flex justify-around py-2'>
							{data.surgeon_block_minutes_contributions?.length > 1 && (
								<div className='justify-self-center max-w-md'>
									<div className='flex items-center gap-2 pb-4 font-semibold'>
										In Block-Minutes Contributions
										<Tooltip
											content={`Use this visualization to see the distribution of surgical minutes by block member. This can be helpful to determine who is the principle user of the allocated block.`}
										>
											<div
												className='material-symbol-sm text-blue-500'
												onClick={() => navigate(Routes.HELP_FAQS + `?goToId=${'commonDefinitions'}`)}
											>
												info
											</div>
										</Tooltip>
									</div>
									<div>
										<InBlockBarChart
											values={data.surgeon_block_minutes_contributions}
											target={Math.max(...data.surgeon_block_minutes_contributions.map((x) => x.y))}
										/>
									</div>
								</div>
							)}
							<div className='px-0 w-full'>
								<div className='flex flex-row justify-between items-start'>
									<div className='flex items-center justify-between gap-2 pl-5 font-semibold'>
										Block Usage Minutes
										<Tooltip
											content={`Use this visualization to see how the components that factor into block utilization are distributed and determine if the amount of block allocated is appropriate.`}
										>
											<div
												className='material-symbol-sm text-blue-500'
												onClick={() => navigate(Routes.HELP_FAQS + `?goToId=${'commonDefinitions'}`)}
											>
												info
											</div>
										</Tooltip>
									</div>

									{!nested && blockNames && (
										<div>
											<ReleaseDialog
												facility_id={selectedFacility ?? null}
												healthsystem_id={selectedSystem ?? null}
												selectedBlock={blockNames.applied[0]}
												blockOptions={blockNames.all}
												requestRelease={(release) => {
													submitRelease(release);
												}}
												requestUndoRelease={(release) => {
													submitUndoRelease(release);
												}}
												filters={{
													days_of_week: daysOfWeek.applied,
													utilization_type: utilizationType.applied,
													add_ons: addOns.applied,
													turnover_threshold: turnoverTimeThreshold.applied,
													start_date: format(dateRange?.applied.startDate, 'M/d/yyyy'),
													end_date: format(dateRange?.applied.endDate, 'M/d/yyyy'),
												}}
											>
												<Button sizeX='sm' sizeY='sm' variant={'secondary'} className='mr-3'>
													Release
												</Button>
											</ReleaseDialog>
										</div>
									)}
								</div>
								{!data.release_patterns.every((item) => item.y === 0) ? (
									<div className='m-auto'>
										<BlockUsageBarChart
											data={{ release_patterns: data.release_patterns }}
											excludedString={excludedString}
											groupBlock={data.surgeon_block_minutes_contributions?.length > 1}
										/>
									</div>
								) : (
									<div className='flex flex-col items-center w-full'>
										<div className='flex flex-col w-full items-center gap-6 p-8 text-p2 text-gray-600 pt-20'>
											<img className='w-44' alt='An empty clipboard' src={image} />
											{'There are no data to display.'}
										</div>
									</div>
								)}
							</div>
						</div>
					</Panel>
					<div className='py-4'></div>
					<DataTable
						title='Block Details'
						keepPageIndexOnLeave={'block_details'}
						tooltipContent={`This table provides a breakdown of the components that factor into block utilization, like in block minutes, turnover, and allocated time. It also includes other helpful values like out-block minutes, case volume, flips, and add-ons. Click on a row to see specific case details for the day.`}
						goToHelpID='commonDefinitions'
						clickableRows={(row) => {
							if (row.utilization > 0) {
								navigate(
									`/block-scorecard/case-details?block_detail_id=${row.block_id}&block_date=${row.date}&util_type=${
										utilizationType.applied
									}&turnover_threshold=${turnoverTimeThreshold.applied}&block_name=${
										data?.block_name
									}&procedures=${procedures?.applied.map((procedure) => encodeURIComponent(procedure.name))}`
								);
							}
						}}
						columns={[
							{
								header: 'Date Found',
								accessorKey: 'date',
								cell: ({ row }) => (
									<span className='py-1 text-p2 text-center'>{row.original.date.toLocaleString('en-US')}</span>
								),
								sortingFn: (rowA, rowB, columnId) => {
									const a = new Date(rowA.getValue(columnId)).getTime();
									const b = new Date(rowB.getValue(columnId)).getTime();
									return b > a ? 1 : -1;
								},
								enableGlobalFilter: false,
								meta: {
									headerClass: 'whitespace-pre-line',
								},
							},
							{
								header: 'Dow',
								accessorKey: 'day_of_week',
								enableGlobalFilter: false,
								sortingFn: (rowA, rowB) => {
									const a = shortDOWToNumber(rowA.original.day_of_week);
									const b = shortDOWToNumber(rowB.original.day_of_week);
									return b > a ? 1 : -1;
								},
							},
							{
								header: 'Allocated',
								accessorKey: 'allocated',
								enableGlobalFilter: false,
								cell: ({ row }) => `${row.original.allocated}m`,
							},
							{
								header: 'In-Block / Out-Block',
								accessorKey: 'in_block',
								enableGlobalFilter: false,
								cell: ({ row }) => {
									return <p className='whitespace-nowrap'>{`${row.original.in_block}m / ${row.original.out_block}m`}</p>;
								},
								meta: {
									headerClass: 'whitespace-pre-line w-24',
								},
							},
							{
								header: 'Turnover',
								accessorKey: 'turnover',
								enableGlobalFilter: false,
								cell: ({ row }) => `${row.original.turnover}m`,
							},
							{
								header: 'Block Turnover',
								accessorKey: 'block_turnover',
								enableGlobalFilter: false,
								cell: ({ row }) => `${row.original.block_turnover}m`,
								meta: {
									headerClass: 'whitespace-pre-line',
								},
							},
							{
								header: 'Flips',
								accessorKey: 'flip_count',
								enableGlobalFilter: false,
							},
							{
								header: 'Cases',
								accessorKey: 'total_cases',
								enableGlobalFilter: false,
							},
							{
								header: 'Add Ons',
								accessorKey: 'add_on_vol',
								enableGlobalFilter: false,
								meta: {
									headerClass: 'whitespace-pre-line',
								},
							},
							{
								header: 'Voluntary Release',
								accessorKey: 'voluntary_release',
								enableGlobalFilter: false,
								cell: ({ row }) => {
									return <p>{row.original.voluntary_release >= 0 ? `${row.original.voluntary_release}m` : 'nullified'}</p>;
								},
								meta: {
									headerClass: 'whitespace-pre-line',
								},
							},
							{
								header: 'Automatic Release',
								accessorKey: 'automatic_release',
								enableGlobalFilter: false,
								cell: ({ row }) => {
									return <p>{row.original.automatic_release >= 0 ? `${row.original.automatic_release}m` : 'nullified'}</p>;
								},
								meta: {
									headerClass: 'whitespace-pre-line',
								},
							},
							{
								header: 'Rooms Used',
								accessorKey: 'unique_rooms_used',
								enableGlobalFilter: false,
								meta: {
									headerClass: 'whitespace-pre-line',
								},
							},
							{
								header: 'Utilization',
								accessorKey: 'utilization',
								enableGlobalFilter: false,
								cell: ({ row }) => {
									let color =
										row.original.utilization >= data.utilization_target_percent
											? 'text-green-500 text-center'
											: 'text-red-500 text-center ';

									const isValueGiven = row.original.utilization >= 0;

									if (!isValueGiven) {
										color = 'text-gray-300 text-center';
									}
									return <p className={color}>{isValueGiven ? `${row.original.utilization}%` : 'Excluded'}</p>;
								},
								meta: {
									bodyClass: util_target_met ? 'bg-green-50' : 'bg-red-50',
									footerClass: util_target_met ? 'bg-green-50' : 'bg-red-50',
									headerClass: util_target_met ? 'bg-green-50 text-center pl-3' : 'bg-red-50 text-center pl-3',
								},
							},
						]}
						data={data.block_details}
					/>
				</>
			)}
		</div>
	);
}

export function Tag({ children }: { children: string | undefined }) {
	return <div className='bg-blue-500 font-secondary text-p2 text-white px-2 py-0.5 rounded-sm'>{children}</div>;
}

export default BlockScorecard;

export interface InBlockBarChartsProps {
	values: {
		x: string | number;
		y: number;
	}[];
	target: number;
	printMode?: boolean;
}

// determines vertical spacing between bars
export function determineHeight(valuesLength: number) {
	return 44 * valuesLength;
}

export function InBlockBarChart({ values, target, printMode }: InBlockBarChartsProps) {
	const [sortDesc, setSortDesc] = useState<boolean | undefined>(true);
	let axisFontSize = 5;
	if (window.innerWidth < 1050) {
		axisFontSize = axisFontSize * 5.5;
	}

	const valuesAxis = {
		axis: { stroke: 0 },
		tickLabels: { fontFamily: fontFamily, fontSize: axisFontSize, padding: 5 },
	};

	// find length of longest name and use to to pad chart
	const longNamePadding =
		Math.max(...values.map((element) => (typeof element.x === 'string' ? element.x.length : 0))) * 1.05;

	const colorCycle = ['#2a67bb', '#106ff4', '#9dc4fb', '#30588d', '#5078a5'];
	return (
		<>
			<div className='flex justify-between items-baseline'>
				<p>Top Minutes</p>
				{!printMode && (
					<Select
						label=''
						sizeY='sm'
						sizeX='sm'
						options={[
							{
								label: 'High to Low',
								value: true,
							},
							{
								label: 'Low to High',
								value: false,
							},
						]}
						defaultValue={{
							label: 'High to Low',
							value: true,
						}}
						onChange={(option) => {
							if (option) {
								setSortDesc(option.value);
							}
						}}
					/>
				)}
			</div>
			<div
				className={`mt-5 ${printMode ? 'max-h-[14rem] w-[23rem] overflow-y-clip' : 'overflow-auto max-h-52 w-[28rem]'}`}
			>
				<VictoryChart
					height={determineHeight(values.length)}
					padding={{ top: 30, bottom: 15, left: 0, right: longNamePadding }}
					domain={{ y: [0, target * 1.5] }}
					horizontal
				>
					<VictoryAxis style={valuesAxis} tickFormat={(t) => (typeof t === 'string' ? t.slice(0, 14) : t)} />
					<VictoryBar
						data={values}
						// we noticed VictoryBar was not preserving the sort order from the endpoint so we are sorting values here
						// we are sorting values one way in the backend and sorting again here...maybe we can get around this behavior in the future
						sortKey='x'
						sortOrder={sortDesc ? 'ascending' : 'descending'}
						cornerRadius={2}
						barWidth={36}
						style={{
							data: {
								fill: ({ datum, index }) => {
									const i = index as number;
									return colorCycle[i % 5];
								},
							},
							labels: { fill: 'black', fontSize: printMode ? '14' : '11', fontFamily: fontFamily },
						}}
						labels={values.map((v) => `${v.x} \n ${v.y.toLocaleString('en-US')} minutes`)}
						labelComponent={<VictoryLabel dx={7} direction='ltr' />}
					/>
				</VictoryChart>
			</div>
		</>
	);
}

export function BlockUsageBarChart({
	data,
	groupBlock,
	excludedString,
	printMode,
}: {
	data: { release_patterns: { x: string; y: number }[] };
	groupBlock: boolean;
	excludedString?: boolean;
	printMode?: boolean;
}) {
	const standard1080pWidth = 1536;
	return (
		<VictoryChart
			maxDomain={{ y: Math.max(...data.release_patterns.map((x) => x.y)) * 1.5 }}
			height={!printMode ? 300 : groupBlock ? window.innerHeight / 2.2 : window.innerHeight / 1.8}
			width={groupBlock ? window.innerWidth / 2.2 : window.innerWidth}
			standalone={true}
			domainPadding={70}
			padding={{
				left: !printMode
					? 80
					: groupBlock
					? window.innerWidth * (80 / standard1080pWidth)
					: window.innerWidth * (70 / standard1080pWidth),
				right: 40,
				top: !printMode ? 40 : groupBlock ? 40 : 10,
				bottom: !printMode
					? 60
					: groupBlock
					? window.innerWidth * (50 / standard1080pWidth)
					: window.innerWidth * (80 / standard1080pWidth),
			}}
		>
			<VictoryAxis
				dependentAxis
				style={{
					axis: { stroke: 'hsl(0 0% 90%)' }, // tailwind.config.js/theme.colors.gray.100
					grid: { stroke: 'hsl(0 0% 90%)' }, // tailwind.config.js/theme.colors.gray.100
					tickLabels: {
						fontFamily: 'Inter',
						fontSize: groupBlock && !printMode ? 10 : groupBlock ? window.innerWidth / 110 : window.innerWidth / 100,
					},
				}}
			/>
			<VictoryAxis
				style={{
					axis: { stroke: 'hsl(0 0% 90%)' }, // tailwind.config.js/theme.colors.gray.100
					tickLabels: {
						fontFamily: 'Inter',
						fontSize: 12,
					},
				}}
				tickFormat={(x) =>
					x === 'Automatic Release' && excludedString ? 'Automatic Release \n(Excluded)' : x.match(/.{1,9}(?:\s|$)/g)
				}
				tickLabelComponent={
					<VictoryLabel
						style={{
							fontFamily: 'Inter',
							fontSize: groupBlock && !printMode ? 10 : groupBlock ? window.innerWidth / 90 : window.innerWidth / 75,
						}}
					/>
				}
			/>
			<VictoryBar
				barWidth={60}
				style={{
					data: {
						// Use RGB values for the bar color to trigger a more natural color space interpolation while
						// animating, as HSL hue rotation tends to feel too extreme.
						// https://github.com/d3/d3-interpolate#color-spaces
						fill: ({ datum }) => {
							switch (datum.x) {
								case 'Allocated':
									// classic blue
									return '#2a67bb';
								case 'In Block':
									// navy blue
									return '#15335d';
								case 'Out Block':
									// light blue
									return '#3f76c1';
								case 'Voluntary Release':
									// sky blue
									return '#9dc4fb';
								case 'Automatic Release':
									// icy blue
									return '#d4e0f1';
								default:
									// ocean blue
									return '#5078a5';
							}
						},
					},
					labels: {
						fontFamily: 'Inter',
						fontSize: !printMode ? 12 : window.innerWidth / 105,
					},
				}}
				cornerRadius={{ top: 4 }}
				data={data.release_patterns}
				labels={({ datum }) => `${Math.round(datum.y)}`}
				animate={printMode ? false : true}
			/>
		</VictoryChart>
	);
}

export function BlockPreviewHeader({
	data,
	selectMode,
	onSelect,
	defaultSelected,
}: {
	data: BlockPatternPreviewObject[];
	selectMode?: boolean;
	onSelect?: (dow: string, i: number, pattern_id: number, start_date: Date) => void;
	defaultSelected?: number;
}) {
	const [selectedIndex, setSelectedIndex] = useState<number | null>(defaultSelected ?? null);

	useEffect(() => {
		setSelectedIndex(null);
	}, []);

	return (
		<>
			{!selectMode && (
				<div className='flex text-p3'>
					{data.map((d, i) => (
						<div className='flex flex-col gap-2 pr-12' key={i}>
							<div className='flex justify-between px-2'>
								<p className='text-right font-semibold uppercase'>{d.day_of_week}</p>
								<p className='pl-1'>{String(d.week_of_month)}</p>
							</div>
							<p className='text-p3 bg-blue-50 w-fit pt-1 pb-0.5 px-2 font-semibold rounded-2xl whitespace-nowrap self-center'>
								{d.range}
							</p>
							<p className='px-2'>({d.total_min} min)</p>
						</div>
					))}
				</div>
			)}

			{!selectMode && data.length === 0 && (
				<div className='flex flex-col gap-2 pr-12 opacity-0'>
					<div className='flex justify-between px-2'>
						<p className='text-right font-semibold uppercase'>None</p>
						<p className='pl-1'>None</p>
					</div>
					<p className='text-p3 bg-blue-50 w-fit pt-1 pb-0.5 px-2 font-semibold rounded-2xl whitespace-nowrap self-center'>
						None-None
					</p>
					<p className='px-2'>None</p>
				</div>
			)}

			{selectMode && onSelect && (
				<div className='flex text-p3'>
					{data.map((d, i) => (
						<div
							className={`flex ${
								selectedIndex === i ? 'border-blue-500' : 'border-white'
							} flex-col gap-2 mr-1 mb-2 p-5 rounded-md cursor-pointer border hover:border hover:border-blue-500`}
							key={i}
							onClick={() => {
								setSelectedIndex(i);
								onSelect(d.day_of_week, i, d.block_pattern_id, d.start_date);
							}}
						>
							<div className='flex justify-between px-2'>
								<p className='text-right font-semibold uppercase'>{d.day_of_week}</p>
								<p className='pl-1'>{String(d.week_of_month)}</p>
							</div>
							<p className='text-p3 bg-blue-50 w-fit pt-1 pb-0.5 px-2 font-semibold rounded-2xl whitespace-nowrap self-center'>
								{d.range}
							</p>
							<p className='px-2'>({d.total_min} min)</p>
							<div className='flex flex-col px-2 pl-1'>
								<div className='flex justify-between items-center w-full'>
									<span className='material-symbols-outlined h-fit text-[1.5em] text-blue-300 w-8 pl-1'>start</span>{' '}
									<p className='text-[1em] text-gray-700'>{new Date(d.start_date + 'T00:00').toLocaleDateString()}</p>
								</div>

								<p className='text-[1em] text-gray-700'>
									{new Date() > new Date(d.end_date + 'T00:00') && (
										<div className='flex justify-between items-center w-full'>
											<span className='material-symbols-outlined h-fit text-[1.7em] text-blue-300 w-8'>last_page</span>{' '}
											<p className='text-[1em] text-gray-700'>{new Date(d.end_date + 'T00:00').toLocaleDateString()}</p>
										</div>
									)}
								</p>
							</div>
						</div>
					))}
				</div>
			)}
		</>
	);
}
